<template>
	 <b-modal ref="modalAjout" hide-footer>
		<template v-slot:modal-title>
			{{ $t("courrier_type.generation") }}
		</template>

		<e-select
            v-model="model_selected"
            id="models"
            track-by="model_id"
            label="model_label"
            class="primary"
            :options="models"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
        >
            <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
        </e-select>

        <div v-if="model_selected" class="text-center">
            <b-button pill variant="primary" class="mt-3" @click.prevent="addModel"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("courrier_type.generer") }}</b-button>
        </div>
	</b-modal>
</template>

<script type="text/javascript">
    import ModelMixin from "@/mixins/Model.js";

	export default {
		name: 'ModalContract',
		mixins: [ModelMixin],
		props: ['tiers'],
		data () {
			return {
				models: [],
				model_selected: null,
				processing: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.models = await this.loadAllModel(0, ['contract_envoie', 'contract_envoie_adresse'])
			},

			openModal() {
				this.$refs.modalAjout.show()
			},

			async addModel() {
				this.processing = true
				const model = await this.createModel(this.tiers.tiers_id, this.model_selected.type.modeltype_id, this.model_selected.model_label, 'contract_envoie')
				await this.cloneModel(this.model_selected.model_id, model.model_id)
				await this.generateCourrier(model.model_id)
				this.processing = false
			}
		},
	}

</script>